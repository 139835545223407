import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet'

const AboutUsPage = ( {data} ) => {

    const title = data.prismicAboutUs.data.title.text
    const bodyText = data.allPrismicAboutUs.nodes[0].data.body_text.html

    return (
        
      <Layout>

        <Helmet>
          <title>About</title>
          <meta name='description' content='About Monarch People' />
        </Helmet>
        
        <div className="page-content rich-text-page">
          <title>{title}</title>
          <h1>{title}</h1>

          <div className="rich-text-body">{parse(bodyText)}</div>
        </div>
        
      </Layout>
    )
  }

export default AboutUsPage

export const query = graphql`
  query {
    prismicAboutUs {
        data {
            title {
                text
            }
            body_text {
                text
            }
        }
    }
    allPrismicAboutUs {
        nodes {
            data {
                body_text {
                    html
                }
            }
        }
    }
  }
`